<template>
  <div class="container" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template #cardContent>
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            :isAdvancedSearch="false"
            :isBillDate="false"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="安装包列表">
      <template #cardContent>
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="multiple"
            :isDeleteBtn="true"
            @handleAdd="openDia"
            @handleDelete="deleteBtn"
          />
          <el-table
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            border
            v-loading="loading"
            :height="tableHeight"
            :max-height="tableHeight"
            ref="multipleTable"
            @row-click="handleRowClick"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column align="center" type="index" width="80" label="序号" />
            <el-table-column
              prop="productNo"
              align="center"
              type="index"
              width="180"
              label="产品编号"
            >
              <template v-slot="scope">
                <el-button type="text" @click="handleDetail(scope.row)">{{
                  scope.row.productNo
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="setupProductName"
              label="产品名称"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              align="center"
              prop="产品名称"
              label="安装包类型"
              show-overflow-tooltip
            >
              <template v-slot="scope">
                <div v-for="(item, index) in dict.type.sys_operating_system" :key="index">
                  <span v-if="scope.row.setupPackageType == item.value">{{
                    item.label
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="packageFileResp.attachFileUrl"
              label="安装包地址"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="packageFileResp.attachFileName"
              label="安装包名称"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="packageFileResp.fileSize"
              label="安装包大小"
              show-overflow-tooltip
              align="center"
            >
              <template v-slot="scope">
                <span v-if="scope.row.packageFileResp != null"
                  >{{ scope.row.packageFileResp.fileSize }}KB</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table>
          <TablePagination
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            :total="total"
            @pagination="getList"
          ></TablePagination>
        </div>
      </template>
    </cardTitleCom>
    <c-dialog :title="title" :width="500" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="100px"
          v-loading="loadingDia"
        >
          <el-form-item label="产品名称" prop="productName">
            <el-input
              size="mini"
              class="disabled-input"
              @click.native="showProduct"
              v-model="form.productName"
              style="width: 220px"
              placeholder="请输入内容"
            >
              <i slot="suffix" class="el-icon-more more"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="安装包类型" prop="setupPackageType">
            <el-select
              size="mini"
              v-model="form.setupPackageType"
              style="width: 220px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dict.type.sys_operating_system"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传安装包" required>
            <OssUpload
              v-model="url"
              :type="uploadType"
              @change="successFile"
              useFileName
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="close2">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 选择产品弹窗 -->
    <selectProductDialog
      :openDialog.sync="openSelectProductDialog"
      :isRadio="true"
      :productTypes="productTypes"
      @select="selectSms"
      :isSunvunApp="isSunvunApp"
      :status="0"
    />
  </div>
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import OperatingButton from "@/views/components/operatingButton/index.vue";
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import cardTitleCom from "@/views/components/cardTitleCom";
// import SeniorSearch from '@/views/components/seniorSearch/index.vue'
// import AccessoryUpload from '@/views/components/accessoryUpload/index.vue'
import selectProductDialog from "@/views/components/selectProductDialog.vue";
import OssUpload from "@/components/oss-upload/index.vue";
import {
  packageConfigDelete,
  packageConfigList,
  packageConfigSave,
  setupPackageDetail,
  packageConfigUpdate,
} from "@/api/tenant/resource/setupPackage"; //选择短信产品弹窗
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
export default {
  name: "index",
  dicts: ["sys_operating_system"],
  components: {
    cardTitleCom,
    seniorSearch,
    // AccessoryUpload,
    selectProductDialog,
    OssUpload,
    OperatingButton,
    TablePagination,
    CDialog,
  },
  data() {
    return {
      loadingDia: false,
      uploadFile: process.env.VUE_APP_BASE_API + "/api/open/oss/file/bigUpload",
      dialogVisible: false, // 弹框
      title: "新增",
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "setupProductNames", name: "产品名称" },
        { parameter: "setupPackageTypes", name: "安装包类型" },
      ],
      tableData: [], // 列表数据
      selectDataList: [], // 列表选择数据
      ids: [],
      loading: false, // 列表加载
      uploadType: [], // 上传文件类型
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      isSunvunApp: true, // 是否是APP
      total: 10,
      url: "", // 上传文件路径
      form: {
        productName: "",
        setupProductId: "",
        setupPackageType: "",
        basAttachFile: {},
      },
      rules: {
        productName: [
          { required: true, message: "请选产品", trigger: ["blur", "change"] },
        ],
        setupPackageType: [
          { required: true, message: "请选产品类型", trigger: "change" },
        ],
      },
      productTypes: [2, 6], // 请求的产品类型
      openSelectProductDialog: false, //选择短信产品弹窗开关
      // 非多个禁用
      multiple: true,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70, // 分页组件高度
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 120;
    },
  },
  created() {
    console.log("上载路径", this.uploadFile);
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async handleDetail(row) {
      this.dialogVisible = true;
      this.loadingDia = true;
      const res = await setupPackageDetail(row.setupPackageId);
      this.url = res.data?.packageFileResp?.attachFileUrl;
      this.form = {
        productName: res?.data?.setupProductName,
        setupPackageType: res?.data?.setupPackageType?.toString(),
        basAttachFile: res?.data?.packageFileResp,
        accountId: res?.data?.accountId,
        setupPackageId: res?.data?.setupPackageId,
        setupProductId: res?.data?.setupProductId,
        tenantId: res?.data?.tenantId,
      };
      this.loadingDia = false;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    // 列表选择
    handleSelectionChange(val) {
      this.selectDataList = val;
      val.forEach((item) => {
        this.ids.push(item.setupPackageId);
      });
      this.multiple = !this.ids.length;
    },
    // 列表点击行
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 新增按钮操作 */
    handleAdd() {
      //点击tree获取的id赋值给商品类别选择项
      let categoryId = this.TreeId || undefined;
      //跳转商品明细页
      this.$router.push({
        name: "GoodsInfoDetail",
        query: {
          type: "add",
          categoryId: categoryId,
        },
      });
    },
    /**列表请求**/
    getList() {
      this.loading = true;
      packageConfigList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    close2() {
      this.reset();
      this.dialogVisible = false;
    },
    close() {
      this.reset();
      this.openSelectProductDialog = false;
    },
    // 新增
    openDia() {
      this.resetForm("form");
      this.dialogVisible = true;
    },
    // 删除按钮
    deleteBtn() {
      packageConfigDelete(this.ids).then((res) => {
        this.$message.success("删除成功");
        this.getList();
      });
    },
    // 上传安装包成功
    successFile(file) {
      console.log("上传成功：", file);
      if (file.fileOldName != "") {
        this.form.basAttachFile = {
          attachFileName: file.fileOldName,
          attachFileUrl: file.fileUrl,
          // filePath: file.data.filePath,
          fileSize: file.fileSize,
        };
      }
    },
    // 删除安装包
    deleteFile(file) {
      if (file.response.code == 200) {
        console.log("删除", file.response.data);
        if (file.response.data.accessUrl == this.form.basAttachFile.attachFileUrl) {
          this.form.basAttachFile = {
            attachFileName: "",
            attachFileUrl: "",
            filePath: "",
            fileSize: "",
          };
        }
      }
    },
    // 弹框提交
    dialogSubmit() {
      console.log("==", this.form);
      if (
        !this.form.basAttachFile.attachFileUrl ||
        this.form.basAttachFile.attachFileUrl == ""
      ) {
        return this.$message.warning("请上传安装包");
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.setupPackageId) {
            packageConfigUpdate(this.form).then((res) => {
              this.dialogVisible = false;
              this.reset();
              this.getList();
              this.$message.success("修改成功");
            });
          } else {
            packageConfigSave(this.form).then((res) => {
              this.dialogVisible = false;
              this.reset();
              this.getList();
              this.$message.success("新增成功");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开选择产品弹框
    showProduct() {
      this.openSelectProductDialog = true;
    },
    // 选择产品
    selectSms(val) {
      // console.log('产品名称', val, val.productName, val.productId)
      val.forEach((item) => {
        this.form.productName = item.productName;
        this.form.setupProductId = item.productId;
      });
      this.openSelectProductDialog = false;
      console.log("this.form===", this.form);
    },
    handleClose1(done) {
      this.openSelectProductDialog = false;
      this.reset();
    },
    reset() {
      this.form = {
        productName: "",
        setupProductId: "",
        setupPackageType: "",
        basAttachFile: { attachFileUrl: "" },
      };
      this.url = "";
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;

      .el-input,
      .el-select {
        width: 170px;
      }
    }
  }

  .tableContent {
    padding: 10px 10px 0 10px;
  }

  .disabled-input {
    ::v-deep .el-input__inner {
      color: #555555 !important;
      background-color: #ffffff !important;
      cursor: pointer;
    }
  }
}
</style>
