<template>
  <!-- 附件管理 -->
  <div class="attachFile" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            :isAdvancedSearch="false"
            :isBillDate="false"
            isSearchInputTitle="附件拓展名，附件名称,附件原名"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="附件列表">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :multiple="!Boolean(ids.length)"
            :isDeleteBtn="true"
            :isAddBtn="false"
            @handleAdd="clickBtn('新增')"
            @handleDelete="clickBtn('删除')"
          >
            <template #specialDiyBtn>
              <!-- 附件上传组件 -->
              <accessoryUpload :showUploadBtn="true" @getFileItems="getFileItemsData" />
            </template>
          </operatingButton>
          <!--                    &lt;!&ndash; 按钮区域 &ndash;&gt;-->
          <!--                    <comBtn-->
          <!--                        @clickBtn="clickBtn"-->
          <!--                        :isCopyBtn="false"-->
          <!--                        :isStartUsingBtn="false"-->
          <!--                        :isAuditBtn="false"-->
          <!--                        :isAddBtn="false"-->
          <!--                    >-->
          <!--                        <template #specialDiyBtn>-->

          <!--                        </template>-->
          <!--                    </comBtn>-->
          <!-- 表格区域 -->
          <div class="table">
            <!-- 表格 -->
            <el-table
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
              @selection-change="handleSelectionChange"
            >
              <el-table-column fixed type="selection" width="50" align="center">
              </el-table-column>
              <el-table-column fixed type="index" label="序号" width="50" align="center">
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="item.prop === 'attachFileName'"
                    type="text"
                    size="small"
                    @click="handlePreview(scope.row)"
                    >{{ scope.row[item.prop] }}
                  </el-button>
                  <div v-else>{{ scope.row[item.prop] }}</div>
                </template>
              </el-table-column>
            </el-table>
            <TablePagination
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              :total="total"
              @pagination="getList"
            ></TablePagination>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import accessoryUpload from "@/views/components/accessoryUpload"; //附件上传组件
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import OperatingButton from "@/views/components/operatingButton/index.vue";
import {
  fileList, //列表
  delFile, //删除
  saveFile, //新增
} from "@/api/tenant/resource/attachFile.js"; //接口api
export default {
  name: "AttachFile",
  components: {
    cardTitleCom,
    OperatingButton,
    accessoryUpload,
    seniorSearch,
    TablePagination,
  },
  data() {
    return {
      loading: false, //遮罩层
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        attachFileName: undefined, //附件名称
      }, //查询
      form: {
        attachFileName: undefined, //附件名称
        attachFileUrl: undefined, //附件地址
        originalFileName: undefined, //附件原名称
        expansionFileName: undefined, //附件拓展名
      }, //表单
      tableData: [], //表格数据
      total: 0,
      ids: [], // 选中数组id
      names: [], //选中的名字数组
      openDialog: false, //操作(新增/编辑)oss对象储存弹窗开关
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: "tenantNo", title: "商户号", width: "200" },
        { prop: "tenantName", title: "商户名称", width: "160" },
        { prop: "attachFileUrl", title: "附件地址", width: "250" },
        { prop: "attachFileName", title: "附件名称" },
        { prop: "originalFileName", title: "附件原名" },
        { prop: "expansionFileName", title: "附件拓展名", width: "120" },
        { prop: "fileSize", title: "附件大小(MB)", width: "120" },
      ],
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "expansionFileNames", name: "附件拓展名" },
        { parameter: "attachFileNames", name: "附件名称" },
        { parameter: "originalFileNames", name: "附件原名" },
      ],
      // 非多个禁用
      multiple: true,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 70, // 分页组件高度
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 120;
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //查看下载附件
    handlePreview(fileItem) {
      // 获取heads中的filename文件名
      let downloadElement = document.createElement("a");
      // 创建下载的链接
      let href = window.URL.createObjectURL(fileItem.attachFileUrl);
      downloadElement.href = href;
      // 下载后文件名
      downloadElement.download = file.name;
      document.body.appendChild(downloadElement);
      // 点击下载
      downloadElement.click();
      // 下载完成移除元素
      document.body.removeChild(downloadElement);
      // 释放掉blob对象
      window.URL.revokeObjectURL(href);
    },
    //获取上传阿里云信息 新增附件后台
    async getFileItemsData(file) {
      this.form.attachFileName = file.fileName;
      this.form.attachFileUrl = file.accessUrl;
      this.form.filePath = file.filePath;
      this.form.fileSize = file.fileSize;
      try {
        await saveFile(this.form);
        this.$message.success("上传附件成功");
        this.getList();
      } catch {}
    },
    //表格选中的数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.attachFileId);
      this.names = selection.map((item) => item.attachFileName);
      console.log("选中的数据项", this.ids);
    },
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    async clickBtn(type) {
      if (type === "删除") {
        console.log("选中的数据项", this.ids, this.ids.length);
        if (this.ids.length <= 0) return this.$message.error("请选择附件");
        this.$confirm(`确定删除${this.names.join(",")}附件`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await delFile(this.ids);
            this.$message.success("删除成功");
            this.getList();
          })
          .catch(() => {});
      }
    },
    //搜索
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    //重置搜索
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        attachFileName: undefined, //附件名称
      };
      this.getList();
    },
    /** 查询列表 */
    getList() {
      this.loading = true;
      fileList(this.queryParams).then((response) => {
        this.tableData = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachFile {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .navSearch {
    padding: 10px;

    .item {
      margin-right: 10px;
    }

    .el-input,
    .el-select {
      width: 200px;
    }
  }

  .tableContent {
    padding: 10px 10px 0 10px;
  }

  ::v-deep .accessory-upload {
    display: inline-block;
  }

  // 去掉动画效果
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: all 0s;
  }
}
</style>
